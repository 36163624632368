/*
Version 0.135

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2022.

!Changelog

2022
	05-08-2022
	- [ADDED] Dynamic Hotspot in HTML
2020
	28-22-2020
	- [CHANGE] Some things
2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_360 = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		/*
		Show the tour loader
		*/
		$('#tour').show();

		/*
		Load the tour
		Load the tour
		Load the tour
		Load the tour
		*/
		var _coordinates_array;
		var _scene_step;
		
		var search1 				= new URLSearchParams(window.location.search);

		/*
		Catch URL
		*/
		var _zoom_on_mousewheel 	= search1.get("mousewheel");
		var _autoplay 				= search1.get("autoplay");
		var _button_color 			= search1.get("button_color");
		var _button_position 		= search1.get("button_position");

		/*
		Load KRPano wrapper
		*/
		embedpano({
			swf 				:globalUrl + "/vtour/tour.swf", 
			xml 				:globalUrl + "/vtour/tour.xml?v=" + Math.random(),
			target 				:"tour",
			html5 				:"only",
			mobilescale 		:1.0,
			passQueryParameters :true,
			focus				:false,
			mwheel 				:true,
			bgcolor 			:"#ffffff"
		});
		
		/*
		Debug: Show
		*/
		if(typeof _is_development === 'boolean' && _is_development == true) {
			$('.js-debug').show();
		}

		/*
		Bind listeners
		*/
		$(document).on('click','#tour',function() {

			specific_360.autorotate('stop');

			/*
			Give coordinates of the click
			*/
			if(typeof _is_development === 'boolean' && _is_development == true) {

				var mouse_at_x = krpano.get("mouse.x");
				var mouse_at_y = krpano.get("mouse.y");

				var _mouse_coordinates = krpano.screentosphere(
					mouse_at_x,
					mouse_at_y
				);

				$('.js-debug').find('.js-click').html(
					(Math.round(_mouse_coordinates.y * 100) / 100).toFixed(2) + ',' + (Math.round(_mouse_coordinates.x * 100) / 100).toFixed(2)
				);

			}

		});

		/*
		Define KRPano
		*/
		krpano 			= document.getElementById('krpanoSWFObject');

		isKRPanoLoaded = 1;

		/*
		Load URL
		*/
		specific_360.updateUrl();
		
		/**
		Check Gyro / VR on Desktop / Mobile
		**/
		switch(_device) {
		
			case 'desktop':
				//$('.js-button-fullscreen').show();
				//$('.js-button-360').hide();
				//$('.js-button-vr').hide();	
			break;
			
			case 'tablet':
				//$('.js-button-fullscreen').hide();
				//$('.js-button-360').show();
				//$('.js-button-vr').hide();	
			break;
			
			case 'smartphone':
				//$('.js-button-fullscreen').show();
				//$('.js-button-360').show();
				//$('.js-button-vr').show();	
			break;
			
		}
		
		// /**
		// Set Autoplay
		// **/
		// //if(_autoplay == 'true') {
		// setTimeout(function() {
		// 	specific_360.autorotate('start');
		// },500);
		// //}
		
		
		/**
		Set menu active item
		**/
		//$('.menu .dropdown a').removeClass('active');
		
		//if(_pano === null) {
		//	$('a[data-url="monster-registratie-9"]').addClass('active');
		//} else {
		//	$('a[data-url="' + _pano + '"').addClass('active');
		//

		/*
		Catch coordinates
		*/
		if(typeof _is_development === 'boolean' && _is_development == true) {
			specific_360.trackCoordinates();
		}
		
	};
		
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
		
		this.autorotate = function(w) {

			if(w == 'stop') {
				krpano.call('set(autorotate.enabled,false)');

				/*
				Start again
				*/
				setTimeout(function() {
					//specific_360.autorotate('start');
				},5000);

			} else {
				krpano.call('set(autorotate.enabled,true)');
			}
		};

		this.toggleInfo = function(
			w
		) {

			/*
			Set: 0
			*/
			if(w == 0) {
				$('.js-info-status').val(1);
			}
			if(w == 1) {
				$('.js-info-status').val(0);
			}

			/*
			Toggle
			*/
			if(
				$('.js-info-status').val() == 0
			) {

				var _marge1 = 0;
				if($(window).width() < 800) {
					_marge1 = -70;
				}

				$('.js-info-puller').find('label').hide();
				$('.js-info-puller').addClass('active');

				$('.js-info').stop().animate({
					'right':_marge1
				},500,'easein');

				$('.js-info-status').val(1);

				$('.js-open').hide();
				$('.js-close').show();

			} else {

				/*
				Check marge
				*/
				var _marge2 = -600;
				if($(window).width() < 800) {
					_marge2 = '-' + ($(window).width()) + 'px';
				} 

				$('.js-info-puller').find('label').show();
				$('.js-info-puller').removeClass('active');

				$('.js-info').stop().animate({
					'right': _marge2
				},500,'easein');

				$('.js-info-status').val(0);

				$('.js-open').show();
				$('.js-close').hide();

			}

		};

		/*
		Update pano URL and go routing
		*/
		this.updateUrl = function(
			new_url
		) {

			/*
			Set: Begin scene
			*/
			if(url[1] === '') {
				
				window.location = globalUrl + '/ahoy-plaza/1/-318|0|4';
				
			} else {
				
				if(typeof new_url !== 'undefined' && new_url !== '') {
					var _urlParams = new URLSearchParams(window.location.search);
					window.history.pushState('obj','',globalUrl + '/' + new_url + '?' + _urlParams);
				}

			}
			
			/*
			Get new URL
			*/
			url = getUrl();

			/*
			Close menu
			*/
			// $('.menu').find('.inside').css({
			// 	'display':'none'
			// });

			/*
			Toggle Info
			*/
			//specific_360.toggleInfo(0);

			/**
			Set Autoplay
			**/
			// setTimeout(function() {
			// 	specific_360.autorotate('start');
			// },500);

			//console.log(_call_scene);

			/*
			Hotspots: Get and Set
			*/
			specific_360.GetAndSetHotspotsAndKRPANO();

			/*
			Catch and active URL
			*/
			// var _url_active_main = './';
			
			// $('.js-nav-item').removeClass('active');

			// if(url[1] !== '') { 
			// 	_url_active_main = url[1];
			// }
			
			// if(_url_active_main !== '') {
			// 	$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
			// }

			specific_menu.setMapAndFloorInMenu();

		};

		/*
		Get: Info
		*/
		this.getInfo = function(
		) {

			$.ajax({
				url: globalUrl + '/a/l/scene-info',
				method:'POST',
				data:{
					scene:url[1],
					subscene:url[2]
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				setTimeout(function() {

					if(data == '') {
						$('.js-info-puller').hide();
						$('.js-info').hide();
					} else {
						$('.js-info-puller').show();
						$('.js-info').show();
					}

					/*
					Zet in infobox
					*/
					$('.js-info-inside').html(data);

				},500);

			})
			.always(function() {
			});

		};

		/*
		Hotspots *********************************************
		Hotspots *********************************************
		Hotspots *********************************************
		Hotspots *********************************************
		*/
		this.GetAndSetHotspotsAndKRPANO = function() {

			$.ajax({
				url: globalUrl + '/a/l/scene-and-hotspots',
				method:'POST',
				data:{
					scene:url[1],
					subscene:url[2]
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				/*
				Scene: Set
				*/
				var _call_scene 			= data.general.scene;

				/*
				Debug: Show scene
				*/
				if(typeof _is_development === 'boolean' && _is_development == true) {
					$('.js-debug').find('.js-scene').html(_call_scene);
				}

				/*
				Position: Set
				*/
				if(url[3] == '') {
					_coordinates_array 		= data.general.coordinates;
				} else {
					_coordinates_array 		= url[3];
				}

				/*
				Get: Info
				*/
				specific_360.getInfo();

				/*
				Get: Coordinates
				*/
				var _coordinates_array	= decodeURIComponent(_coordinates_array);
					_coordinates_array	= _coordinates_array.split('|');

				/*
				Load: Scene
				*/
				krpano.call("loadscene('scene_" + _call_scene + "',null,MERGE,BLEND(0.4));");
				krpano.call("lookat(" + _coordinates_array[0] + ',' + _coordinates_array[2] + "," + _coordinates_array[3] + ");");

				/*
				Hotspots
				*/
				var _hotspot_count  = 1;

				$.each(data.hotspots, function(index, element) {
		            	
		            var _button_type 	= element.button;
		            var _button_title 	= element.title;
		            var _button_icon 	= element.icon;
		            var _button_ath_atv = element.ath_atv.split(',');
		            var _button_js		= element.js;
		            var _button_cursor	= element.cursor;

		            /*
		            Button 1 ********************
		            Button 1 ********************
		            Button 1 ********************
		            Button 1 ********************
		            */
		            var _hotspot_name	= 'hotspot_' + _hotspot_count;

		            var _hotpot_button_str;

		            switch(_button_type) {

			            case 1: // route
							
							if(_button_title) {
								_hotpot_button_str = '[div class="hotspot hotspot_' + _button_type + ' ' + _button_icon + '"]';
							} else {
								_hotpot_button_str = '[div class="hotspot no-label hotspot_' + _button_type + ' ' + _button_icon + '"]';
							}

									_hotpot_button_str += '[div class="arrow"]';
										_hotpot_button_str += '[div class="icon ' + _button_icon + '"][/div]';
									_hotpot_button_str += '[/div]';
									if(_button_title) {
										_hotpot_button_str += '[div class="label"]';
											_hotpot_button_str += '[span class="title"]' + _button_title + '[/span]';
										_hotpot_button_str += '[/div]';
									}
								_hotpot_button_str += '[/div]';

						break;

						case 2:  // info
							
							if(_button_title) {
								_hotpot_button_str = '[div class="hotspot hotspot_' + _button_type + ' ' + _button_icon + '"]';
							} else {
								_hotpot_button_str = '[div class="hotspot no-label hotspot_' + _button_type + ' ' + _button_icon + '"]';
							}
							
									_hotpot_button_str += '[div class="arrow yellow"]';
										_hotpot_button_str += '[div class="icon ' + _button_icon + '"][/div]';
									_hotpot_button_str += '[/div]';
									
									if(_button_title) {
										_hotpot_button_str += '[div class="label"]';
											_hotpot_button_str += '[span class="title"]' + _button_title + '[/span]';
										_hotpot_button_str += '[/div]';
									}
									
								_hotpot_button_str += '[/div]';

						break;

					}

					/*
					KRPano: Add and set
					KRPano: Add and set
					KRPano: Add and set
					KRPano: Add and set
					*/
					krpano.call('addhotspot(' + _hotspot_name + ')');

					// krpano.set('hotspot[' + _hotspot_name + '].type', 'text');
					// krpano.set('hotspot[' + _hotspot_name + '].renderer', 'css3d');

					// krpano.set('hotspot[' + _hotspot_name + '].html,' + _hotpot_button_1_str);

					// krpano.set('hotspot[' + _hotspot_name + '].atv,' + parseInt(_button_ath_atv[0]));
					// krpano.set('hotspot[' + _hotspot_name + '].ath,' + parseInt(_button_ath_atv[1]));

					// krpano.set('hotspot[' + _hotspot_name + '].bgalpha',0);
					// krpano.set('hotspot[' + _hotspot_name + '].bgborder',0);

					// krpano.set('hotspot[' + _hotspot_name + '].onclick,js(' + _button_js + ')');

					krpano.call('set(hotspot[' + _hotspot_name + '].type,text);');
					krpano.call('set(hotspot[' + _hotspot_name + '].renderer,css3d);');
					krpano.call('set(hotspot[' + _hotspot_name + '].html,' + _hotpot_button_str + ');');

					krpano.call('set(hotspot[' + _hotspot_name + '].atv,' + parseInt(_button_ath_atv[0]) + ');');
					krpano.call('set(hotspot[' + _hotspot_name + '].ath,' + parseInt(_button_ath_atv[1]) + ');');

					krpano.call('set(hotspot[' + _hotspot_name + '].bgalpha,0);');
					krpano.call('set(hotspot[' + _hotspot_name + '].bgborder,0);');

					krpano.call('set(hotspot[' + _hotspot_name + '].handcursor,' + _button_cursor + ');');

					krpano.call('set(hotspot[' + _hotspot_name + '].onclick,js(' + _button_js + '));');

					// var _debug = "console.log(" + $(this) + ");";

					// krpano.call('set(hotspot[' + _hotspot_name + '].onover,js(' + _debug + '));'); // WORKS

					/*
					Count: Add
					*/
					_hotspot_count++;

		        });

		   //      setTimeout(function() {

		   //      	var hotspot_count = krpano.get('hotspot.count');

			  //       console.log(hotspot_count);
			        
					// for(i = 0; i < hotspot_count; i++){
					// 	console.log(krpano.get('hotspot[ ' + i + '].name'));
					//     console.log(krpano.get('hotspot[ ' + i + '].type'));
					//     console.log(krpano.get('hotspot[ ' + i + '].renderer'));
					// }

		   //      },1000);

			})
			.always(function() {
			});

		};

		/*
		Coordinates
		*/
		this.trackCoordinates = function() {
					
			/**
			Clear: Timeout
			**/
			clearTimeout(global_timeout);
		
			/**
			Track Coordinates
			**/
			global_timeout = setInterval(function() {
				
				_hlookat = krpano.get('view.hlookat').toString().substring(0,4);
				_vlookat = krpano.get('view.vlookat').toString().substring(0,4);
				
				// var _url = new URL(document.URL);
				// var _pano = _url.searchParams.get("p");
				// var _look = _url.searchParams.get("l");
				// _look = _look.replace(/|/g,',');
				
				$('.js-debug').find('.js-view').html(
					Math.round(_hlookat) + '|0|' + Math.round(_vlookat)
				);
				
				// window.history.pushState('obj','','./?p=' + _pano + '&l=' + Math.round(_hlookat) + '|0|' + Math.round(_vlookat));
				
			},1000);
			
			
		};
		
		/*
		Tooltip
		*/
		this.tooltip = function(i,href) {
			
			if(i==='on') {
				
				if(isTooltip === 0 && isPopupOpen === 0 && screenWidth > 600) {
					
					_get = href.split('/');
					
					/**
					Get tooltip text from localStorage (JSON)
					**/
					var storage 		= localStorage.getItem('tooltips');
					var get_tooltips 	= JSON.parse(storage);
					var tooltip 		= get_tooltips[_get[0]][_get[1]];
					
					$('.tooltip .js-tooltip').html(tooltip);
				
					/**
					Show Tooltip
					**/
					$('.tooltip').show();
					
					isTooltip = 1;
					
				}
				
				/**
				Update Tooltip on Mousemove
				**/
				_width = $('.tooltip .js-tooltip').outerWidth();
						
				$('.tooltip').css({
				   left:  mouse_posX - ((_width/2)-5),
				   top:   mouse_posY - 100
				});
				
			} else {
				
				if(isTooltip === 1) {
				
					$('.tooltip').hide();
					isTooltip = 0;
				
				}
			}
			
		};
		
		/*
		Open: Popup / Hotspot
		*/
		this.openPopup = function(id) {
			
			/**
			Hide tooltip on open popup
			**/
			$('.tooltip').hide();
			isTooltip = 0;
			
			router.beforeRoute(globalUrl + '/' + id,1);
			popup.open(0,'popspot/' + id);
			
		};
	
		/*
		Toggle: Helpscreen
		*/
		this.toggleHelpscreen = function() {
			
			popup.open(0,'helpscreen');
			
		};
		
		/*
		Toggle: Map
		*/
		this.toggleMap = function() {
			
			_url = 'map/' + url[1];
			if(urlParts[1] !== '') {
				_url = _url + '/' + url[2] + '/' + urlParts[3];
			}
			
			popup.open(0,_url);
			
		};
		
		/*
		Toggle: VR
		*/
		this.toggleVR = function() {
			
			/**
			Disable hover
			**/
			//$('.js-button-vr').unbind("mouseenter mouseleave");
			
			var krpano = document.getElementById('krpanoSWFObject');
		
			if(isVR === 0) {
				krpano.call("webvr.enterVR()");
				//$('.js-button').hide();
				// $('js-button-gyroscope').hide();
			} else {
				//$('.js-button').show();
				//$('js-button-gyroscope').show();
			}
			
		};
		
		/*
		Toggle: Gyroscope
		*/
		this.toggleGyroscope = function() {
			
			/**
			Disable hover
			**/
			//$('.js-button-360').unbind("mouseenter mouseleave");
			
			var krpano = document.getElementById('krpanoSWFObject');
		
			if(isGyro === 0) {
				krpano.set("plugin[gyro].enabled", "true");
				$('.js-button-fullscreen').hide();
				$('.js-button-vr').hide();
				// $('.js-gyroscope-onoff').removeClass('icon-extra-off');
				// $('.js-gyroscope-onoff').addClass('icon-extra-on');
				isGyro = 1;
			} else {
				krpano.set("plugin[gyro].enabled", "false");
				$('.js-button-fullscreen').show();
				$('.js-button-vr').show();
				// $('.js-gyroscope-onoff').removeClass('icon-extra-on');
				// $('.js-gyroscope-onoff').addClass('icon-extra-off');
				isGyro = 0;
			}
			
		};
		
		/*
		Toggle: Fullscreen
		*/
		this.toggleFullscreen = function() {
			
			if(isFullscreen === 1) {
				
				/*
				Switch icon
				*/
				$('.js-button-fullscreen').removeClass('active');
				
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
				
				isFullscreen = 0;
				
			} else {
				
				var element = document.documentElement;
				
				  if(element.requestFullscreen) {
					element.requestFullscreen();
				  } else if(element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				  } else if(element.webkitRequestFullscreen) {
					element.webkitRequestFullscreen();
				  } else if(element.msRequestFullscreen) {
					element.msRequestFullscreen();
				  }
				  
				  
				/*
				Switch icon
				*/
				$('.js-button-fullscreen').addClass('active');
				  
				isFullscreen = 1;
			}
			
		};
			
}).apply(specific_360); 
/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2021
	20-01-2021
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_menu = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	this.onready = function() {

		/*
		Header: Buttons Map en Menu
		Header: Buttons Map en Menu
		Header: Buttons Map en Menu
		Header: Buttons Map en Menu
		*/
		$(document).on('click','.js-button-map',function() {
			
			if(
				$('.js-button-map').hasClass('closed')
			) {
				_action = 'open';
			} else {
				_action = 'close';
			}

			specific_menu.openAndClose('map',_action);

		});

		$(document).on('click','.js-button-menu',function() {
			
			if(
				$('.js-button-menu').hasClass('closed')
			) {
				_action = 'open';
			} else {
				_action = 'close';
			}

			specific_menu.openAndClose('menu',_action);

		});

		/*
		Floorbutton: Menu
		Floorbutton: Menu
		Floorbutton: Menu
		Floorbutton: Menu
		*/
		$(document).on('click','.menu .js-floor-button',function() {

			var _w = $(this).data('floor');

			specific_menu.openMenuFloor(_w);

		});

			/*
			Scenebutton
			*/
			$(document).on('click','.js-scene-button',function() {

				var _w = $(this).data('scene');

				if(
					_w == 'rtm-stage-1' ||
					_w == 'rtm-stage-2' ||
					_w == 'rtm-stage-3'
				) {
					_w = 'rtm-stage';
				}

				specific_menu.gotoScene(_w);

			});

		/*
		Floorbutton: Map
		Floorbutton: Map
		Floorbutton: Map
		Floorbutton: Map
		*/
		$(document).on('click','.map .js-floor-button',function() {

			var _w = $(this).data('floor');

			specific_menu.openMapFloor(_w);			

		});

			/*
			Scenebutton
			*/
			$(document).on('click','.js-floorplan-button',function() {

				var _w = $(this).data('scene');

				/*
				Reset to RTM Stage
				*/
				if(
					_w == 'rtm-stage-1' ||
					_w == 'rtm-stage-2' ||
					_w == 'rtm-stage-3'
				) {
					_w = 'rtm-stage';
				}

				specific_menu.gotoScene(_w);

			});
		
	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.setMapAndFloorInMenu = function() {

		/*
		Floor
		*/
		$('.js-floor').removeClass('active');
		$('.js-floor-button').removeClass('active');

		$('.js-floorplan').removeClass('active');
		$('.js-floorplan-button').removeClass('active');

		/*
		Menu: Active
		*/
		$('.js-scene-button').removeClass('active');
		$('.js-scene-button[data-scene="' + url[1] + '"]').addClass('active');

		/*
		Map: Active
		*/
		var _w = url[1];

		$('.js-floorplan-button').removeClass('active');

		if(
			_w == 'rtm-stage'
		) {
			$('.js-floorplan-button[data-scene="rtm-stage-1"]').addClass('active');
			$('.js-floorplan-button[data-scene="rtm-stage-2"]').addClass('active');
			$('.js-floorplan-button[data-scene="rtm-stage-3"]').addClass('active');
		} else {
			$('.js-floorplan-button[data-scene="' + _w + '"]').addClass('active');
		}

		switch(url[1]) {
			/*
			Floor 1 ************
			Floor 1 ************
			Floor 1 ************
			Floor 1 ************
			*/
			case 'ahoy-plaza':
			case 'entree-ahoy-arena':
			case 'ahoy-arena':

			case 'foyer-arena-1':
			case 'foyer-ahoy-arena-1':
			case 'foyer-ahoy-arena-2':
			
			case 'entrance-RTM-A':
			case 'entrance-RTM-B':

			case 'rtm-stage':

			case 'bar-restaurant-seventy-one':

			case 'hal-1':
			case 'hal-2':
			case 'hal-3':
			case 'hal-4':
			case 'hal-5':
				$('.js-floor-1').addClass('active');
				$('.js-floor-button-1').addClass('active');

				$('.js-floorplan-1').addClass('active');
				$('.js-floorplan-button-1').addClass('active');

				$('.js-mapssmall').addClass('floor-1');
				$('.js-mapssmall').removeClass('floor-2');
				$('.js-mapssmall').removeClass('floor-3');

			break;

			/*
			Floor 2 ************
			Floor 2 ************
			Floor 2 ************
			Floor 2 ************
			*/
			case 'foyer-ahoy-exclusive-lounge':
			case 'ahoy-exclusive-lounge':

			case 'foyer-2-1-a':
			case 'foyer-2-1-b':
			case 'foyer-2-2-a':
			case 'foyer-2-2-b':
			case 'foyer-2-2-c':
			case 'foyer-2-3':
			
			case 'dock-hal-1':
			case 'dock-hal-3':
			case 'dock-hal-4':
			case 'dock-hal-5':

			case 'dock-1':
			case 'dock-3':
			case 'dock-4':
			case 'dock-6':
			case 'dock-10':
			case 'dock-12':
			case 'dock-13':
			case 'dock-14':
			case 'dock-15':
			case 'dock-16':
			case 'dock-17':
				$('.js-floor-2').addClass('active');
				$('.js-floor-button-2').addClass('active');

				$('.js-floorplan-2').addClass('active');
				$('.js-floorplan-button-2').addClass('active');

				$('.js-mapssmall').removeClass('floor-1');
				$('.js-mapssmall').addClass('floor-2');
				$('.js-mapssmall').removeClass('floor-3');
			break;

			/*
			Floor 3 ************
			Floor 3 ************
			Floor 3 ************
			Floor 3 ************
			*/
			case 'foyer-3-1-a':
			case 'foyer-3-1-b':
			case 'foyer-3-2-a':
			case 'foyer-3-2-b':

			case 'port-1':
			case 'port-3':
			case 'port-4':
			case 'port-5':
			case 'port-7':
			case 'port-8':

			case 'rotterdam':
				$('.js-floor-3').addClass('active');
				$('.js-floor-button-3').addClass('active');

				$('.js-floorplan-3').addClass('active');
				$('.js-floorplan-button-3').addClass('active');

				$('.js-mapssmall').removeClass('floor-1');
				$('.js-mapssmall').removeClass('floor-2');
				$('.js-mapssmall').addClass('floor-3');
			break;

		}
		
	};

	this.openAndClose = function(w,_action) {

		/*
		Close all overlay
		*/
		$('.js-button-map').addClass('closed');
		$('.js-button-map').find('.icon').removeClass('close');
		$('.js-overlay-map').hide();

		$('.js-button-menu').addClass('closed');
		$('.js-button-menu').find('.icon').removeClass('close');
		$('.js-overlay-menu').hide();

		$('.js-overlay').hide();

		$('.js-overlay').removeClass('menu map');

		/*
		Open or close?
		*/
		if(_action == 'open') { 
	
			$('.js-button-' + w).removeClass('closed');
			$('.js-button-' + w).find('.icon').addClass('close');
			$('.js-overlay-' + w).show();

			$('.js-overlay').addClass(w);

			$('.js-overlay').fadeIn(300);

		}

	};

	/*
	Scene: Go to
	*/
	this.gotoScene = function(w) {

		$('.overlay').fadeOut(300);

		specific_360.updateUrl(w);

		specific_menu.openAndClose('menu','close');
		specific_menu.openAndClose('map','close');

	};

	/*
	Floor: Show scenes
	*/
	this.openMenuFloor = function(w) {

		$('.menu .js-floor').hide();
		$('.menu .js-floor-button').removeClass('active');

		$('.menu .js-floor-button-' + w).addClass('active');
		$('.menu .js-floor-' + w).show();

		$('.js-scenes').animate({ scrollTop: 0 }, 0);

	};

	/*
	Map: Show floorplans
	*/
	this.openMapFloor = function(w) {

		$('.map .js-floorplan').hide();
		$('.map .js-floor-button').removeClass('active');

		$('.map .js-floor-button-' + w).addClass('active');
		$('.map .js-floorplan-' + w).show();

		$('.js-maps').animate({ scrollTop: 0 }, 0);

	};

		
}).apply(specific_menu); 